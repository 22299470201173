@import "./reset.scss";

$background-color: #f7f8fb;
$primay-font: #2e3849;
$color-disabled: rgba(128, 128, 128, 0.418);
$color-warning: rgb(250, 164, 4);
$btn-primary: #1f61dc;
$btn-secondary: pink;
$btn-background: rgb(160, 160, 160);

$color-inactive: #ae00ff;
$color-active: #adff2f;

$size-desktop: 1919px;
$size-tablet-landscape: 768px;
$size-tablet: 480px;
$size-phone: 480px;

body {
  font-family: Roboto, "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  background-color: $background-color;
  color: $primay-font;
}

.main-container {
  width: 100%;
}
.page-container {
  margin: auto;
}

.primary-table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;

  th {
    text-align: left;

    span {
      color: #2e3849;
    }
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid rgba(0, 0, 0, 0.144);
  }

  // Phone Ajustments for table
  @media only screen and (max-width: $size-phone) {
    th {
      display: none;
    }
    tbody {
      tr {
        display: flex;
        flex-direction: column;
        border-top: 4px solid #f5f6f8;
        td {
          font-size: 1rem;
          vertical-align: middle;
          text-align: left;
          padding-left: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          span {
            color: #2e3849;
          }
          .table-row-subtext {
            font-size: 1rem;
            color: #5a6881;
          }
        }
        .primary-table-link {
          // hack for semi bold
          text-shadow: 0 0 0 $primay-font;
          text-decoration: underline;
        }
      }
    }
  }
}

.cs-billing-table-container {
  overflow-x: auto;
  margin: 0 -16px;
  padding: 0 16px;
}

.cs-billing-table {
  min-width: 800px;
}

.cs-billing-row td {
  padding: 12px 8px;
}

.cs-date-cell {
  white-space: nowrap;
}

.cs-date {
  font-weight: 500;
  margin-bottom: 4px;
}

.cs-time {
  color: #666;
  font-size: 0.9em;
}

.cs-cmd-cell {
  font-family: monospace;
  font-size: 1.1em;
}

.cs-location-cell {
  min-width: 200px;
}

.cs-customer {
  font-weight: 500;
  margin-bottom: 4px;
}

.cs-address {
  color: #666;
  font-size: 0.9em;
  line-height: 1.4;
}

.cs-items-cell {
  min-width: 150px;
}

.cs-item {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.cs-item:last-child {
  margin-bottom: 0;
}

.cs-item-name {
  color: #444;
}

.cs-item-units {
  font-weight: 500;
}

.cs-report-link {
  display: inline-block;
  padding: 6px 12px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #0066cc;
  text-decoration: none;
  font-size: 0.9em;
}

.cs-report-link:hover {
  background: #e5e5e5;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .cs-billing-table {
    font-size: 0.9em;
  }

  .cs-billing-row td {
    padding: 16px 8px;
  }

  .cs-date {
    font-size: 1.1em;
  }

  .cs-customer {
    font-size: 1.1em;
  }

  .cs-item {
    flex-direction: column;
    gap: 2px;
  }

  .cs-item-units {
    padding-left: 8px;
  }

  .cs-report-link {
    padding: 8px 16px;
    text-align: center;
  }
}
